// https://www.gatsbyjs.com/tutorial/authentication-tutorial/
// https://github.com/strapi/strapi-examples/blob/master/login-react/src/pages/LoginRedirect.js

export const isBrowser = () => typeof window !== "undefined";

export const getJWT = () =>
    isBrowser() && window.localStorage.getItem("JWT")
        ? window.localStorage.getItem("JWT")
        : "";

export const getUser = () =>
    isBrowser() && window.localStorage.getItem("user")
        ? JSON.parse(window.localStorage.getItem("user"))
        : {};

export const getRedirectTo = () =>
    isBrowser() && window.localStorage.getItem("redirectTo")
        ? window.localStorage.getItem("redirectTo")
        : "";

export const setJWT = (JWT) => window.localStorage.setItem("JWT", JWT);

export const setUser = (user) => window.localStorage.setItem("user", user);

export const setRedirectTo = (url) =>
    window.localStorage.setItem("redirectTo", url);

export const isLoggedIn = () => {
    const JWT = getJWT();

    return !!JWT;
};

export const login = () => {
    if (isBrowser())
        window.location.href = `${process.env.GATSBY_STRAPI_BACKEND_URL}/connect/google`;
};

export const logout = (callback?) => {
    setJWT("");
    setUser({});
    callback && callback();
};
