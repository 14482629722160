import * as React from "react";
import {
    Button,
    Nav,
    Navbar,
    Row,
    Badge,
    Container,
    Alert,
    Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { Link, navigate } from "gatsby";
import { logout, isLoggedIn, getUser } from "../../services/auth";
import BottomNav from "../../components/bottomnav";

import "../../styles/index.scss";

function AkunButton({ to, icon, title, subTitle, previewText, ...props }) {
    return (
        <li
            className="akun-button nav-item py-2 d-flex align-items-center nav-link cursor-pointer"
            {...props}
        >
            {icon && (
                <FontAwesomeIcon icon={icon} className="text-muted mr-3" />
            )}
            <div className="flex-grow-1">
                {to ? (
                    <Link to={to} className="nav-link p-0 text-body d-block">
                        {title}
                    </Link>
                ) : (
                    <div>{title}</div>
                )}
                {subTitle && <div className="small text-muted">{subTitle}</div>}
            </div>
            <span className="d-flex align-items-center">
                {previewText && (
                    <small className="text-danger">{previewText}</small>
                )}
                <FontAwesomeIcon
                    icon={fa.faChevronRight}
                    transform="shrink-6"
                    className="text-muted ml-2"
                />
            </span>
        </li>
    );
}

const AkunSaya = () => (
    <>
        <Container fluid className="py-3 bg-white">
            <div className="font-weight-bold">
                {isLoggedIn() && getUser().username}
            </div>
            {/*
        <small className="text-muted d-flex align-items-center">
            Edit profil
            <FontAwesomeIcon icon={fa.faChevronRight} transform="shrink-6" className="ml-1"/>
        </small>
        */}
        </Container>
        <nav>
            <ul className="nav flex-column mb-3 bg-white">
                <AkunButton
                    to="kas"
                    icon={fa.faWallet}
                    title="Kas"
                    subTitle="Dukung Islah 24 dengan kas-mu"
                    previewText="Belum lunas"
                />
            </ul>
            {isLoggedIn() && (
                <ul className="nav flex-column bg-white">
                    <AkunButton
                        icon={fa.faSignOutAlt}
                        title="Logout"
                        onClick={() => {
                            logout();
                            navigate("/");
                        }}
                    />
                </ul>
            )}
        </nav>
    </>
);

const AkunPage = () => {
    return (
        <>
            <main>
                <section id="akun-saya">
                    <AkunSaya />
                </section>
                <div className="my-3 py-3 container text-center text-muted">
                    <small>Hanya itu untuk sekarang</small>
                </div>
            </main>
            <BottomNav />
        </>
    );
};

export default AkunPage;
